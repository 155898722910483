import React, { useEffect, useState } from "react";
import "./Toolbar.sass";

import FilterICon from "../assets/filter.png";

function Toolbar({ value, placeholder, onChange, onSidebarOpen, events }) {
  const [sticky, setSticky] = useState("");

  // on render, set listener
  useEffect(() => {
    console.log("hello");
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  }, []);

  const isSticky = () => {
    /* Method that will fix header after a specific scrollable */
    const scrollTop = window.scrollY;
    const stickyClass = scrollTop >= 50 ? "is-sticky" : "";
    setSticky(stickyClass);
  };

  useEffect(() => {
    document
      .querySelector("input[type=text]")
      .style.setProperty("--c", `${events.search_input_color}`);
  }, [events.search_input_color]);

  return (
    <div className={`toolbar-wrapper ${sticky}`}>
      <div className="toolbar">
        <div className="toolbar-search">
          <input
            style={{
              color: events.search_input_color,
              backgroundColor: events.search_input_background,
            }}
            type="text"
            placeholder={placeholder}
            value={value}
            onChange={(e) => onChange(e.target.value)}
          />
        </div>
        <div className="toolbar-buttons">
          <button onClick={() => onChange("")}>✕</button>
          <button onClick={() => onSidebarOpen()}>
            <img
              src={FilterICon}
              style={{ width: "16px", height: "16px" }}
              alt=""
            />
          </button>
        </div>
      </div>
    </div>
  );
}

export default Toolbar;
