import React, { Component } from "react";
import "./App.sass";
import Content from "./Content";
import Events from "./Events";
import Toolbar from "./Toolbar";
import Header from "./Header";
import KeywordButtons from "./KeywordButtons";
import Sidebar from "./Sidebar";
import { sortArray } from "../lib/arrayHelpers";
import SortButton from "./SortButton";
import PasswordModal from "./PasswordModal";
import toDate from "../lib/toDate";

class App extends Component {
  state = {
    events: {
      title: "",
      search_placeholder: "",
      search_keyword_placeholder: "",
      events_password: "",
      protected_content_text: "",
      password_button_text: "",
      events: [],
    },
    show_pwd_modal: false,
    events_keywords: [],
    keywords_list: [],
    events_list: [],
    sort_types: "",
    headerTitle: "",
    header_backgroundColor: "",
    page_background_color: "",
    search_event_term: "",
    search_keyword_term: "",
    header_font_color: "",
    filters: {
      include: [],
      exclude: [],
    },
    is_open_sidebar: false,
    collapse_keywords: false,
  };

  toggleSidebar = () => {
    this.setState((prevState) => ({
      ...prevState,
      is_open_sidebar: !prevState.is_open_sidebar,
    }));
  };

  toggleKeywordsCollapse = () => {
    this.setState((prevState) => ({
      ...prevState,
      collapse_keywords: !prevState.collapse_keywords,
    }));
  };

  handleEventSearch = (value) => {
    this.setState((prevState) => ({ ...prevState, search_event_term: value }));
  };

  handleKeyWordSearch = () => {
    const f = this.state.events.events.slice();
    const { search_event_term } = this.state;
    const result = f.filter(
      (i) =>
        i.id === search_event_term ||
        i.title.toLowerCase().indexOf(search_event_term.toLowerCase()) !== -1 ||
        i.keywords.some(
          (k) => k.toLowerCase() === search_event_term.toLocaleLowerCase()
        )
    );

    this.setState((prevState) => ({ ...prevState, events_list: result }));
  };

  handleSetFilterInclude = (keyword) => {
    const include_array = this.state.filters.include.slice();
    const exclude_array = this.state.filters.exclude.slice();

    const is_include = include_array.indexOf(keyword);
    const is_exclude = exclude_array.indexOf(keyword);

    if (is_exclude > -1) {
      exclude_array.splice(is_exclude, 1);
      this.setState((prevState) => ({
        ...prevState,
        filters: {
          ...prevState.filters,
          exclude: exclude_array,
        },
      }));
    }

    if (is_include > -1) {
      include_array.splice(is_include, 1);
      this.setState((prevState) => ({
        ...prevState,
        filters: {
          ...prevState.filters,
          include: include_array,
        },
      }));
    } else {
      this.setState((prevState) => ({
        ...prevState,
        filters: {
          ...prevState.filters,
          include: [...prevState.filters.include, keyword],
        },
      }));
    }
  };

  handleSetFilterExclude = (keyword) => {
    const include_array = this.state.filters.include.slice();
    const exclude_array = this.state.filters.exclude.slice();

    const is_include = include_array.indexOf(keyword);
    const is_exclude = exclude_array.indexOf(keyword);

    if (is_include > -1) {
      include_array.splice(is_include, 1);
      this.setState((prevState) => ({
        ...prevState,
        filters: {
          ...prevState.filters,
          include: include_array,
        },
      }));
    }

    if (is_exclude > -1) {
      exclude_array.splice(is_exclude, 1);
      this.setState((prevState) => ({
        ...prevState,
        filters: {
          ...prevState.filters,
          exclude: exclude_array,
        },
      }));
    } else {
      this.setState((prevState) => ({
        ...prevState,
        filters: {
          ...prevState.filters,
          exclude: [...prevState.filters.exclude, keyword],
        },
      }));
    }
  };

  handleSetEventKeywords = () => {
    const events = this.state.events_list.slice();
    let list = [];
    if (events.length > 0) {
      for (let i = 0; i < events.length; i++) {
        const event = events[i];
        for (let j = 0; j < event.keywords?.length; j++) {
          const keyword = event.keywords[j];
          if (list.indexOf(keyword) === -1) {
            list.push(keyword);
          }
        }
      }
    }

    this.setState({ events_keywords: list, keywords_list: list });
  };

  handelSortByPriority = () => {
    const copyArray = this.state.events.events.slice();
    const sorted = sortArray(copyArray, "priority").filter(
      (i) => i.priority !== 0
    );
    this.setState({ events_list: sorted });
  };

  handleSortByDueDate = () => {
    const copyArray = this.state.events.events.slice();
    const sorted = copyArray
      .filter(
        (i) => i.dueDate?.length !== 0 && i.keywords.some((k) => k !== "done")
      )
      .sort((a, b) => toDate(a.dueDate) - toDate(b.dueDate));
    this.setState({ events_list: sorted });
  };

  handleSetSortType = (type) => {
    const { sort_types } = this.state;

    if (type === sort_types) {
      this.setState((prevState) => ({ ...prevState, sort_types: "" }));
    } else {
      this.setState((prevState) => ({ ...prevState, sort_types: type }));
    }
  };

  handleSortEvents = () => {
    const list_of_events = this.state.events.events.slice();
    const types = this.state.sort_types;

    switch (types) {
      case "priority":
        this.handelSortByPriority();
        break;
      case "dueDate":
        this.handleSortByDueDate();
        break;
      default:
        this.setState((prevState) => ({
          ...prevState,
          events_list: list_of_events,
        }));
    }
  };

  handleSetIncludesEvents = (events, includes) => {
    let includes_arr = [];
    let includes_events = [];
    for (let i = 0; i < includes.length; i++) {
      const include = includes[i];
      for (let j = 0; j < events.length; j++) {
        if (events[j]?.keywords?.some((i) => i === include)) {
          includes_arr.push(events[j]);
        }
      }
    }
    for (let i = 0; i < includes_arr.length; i++) {
      const index = includes_events.findIndex(
        (e) => e.title === includes_arr[i].title
      );

      if (index === -1) {
        includes_events.push(includes_arr[i]);
      }
    }

    return includes_events;
  };

  handleSetExcludeEvents = (events, excludes) => {
    let events_list = events.slice();

    let titles = [];

    for (let i = 0; i < excludes.length; i++) {
      const exclude = excludes[i];
      for (let j = 0; j < events_list.length; j++) {
        if (events_list[j].keywords.some((k) => k === exclude)) {
          const title = events_list[j].title;
          titles.push(title);
        }
      }
    }

    if (titles.length > 0) {
      for (let t = 0; t < titles.length; t++) {
        const title = titles[t];
        const index = events_list.findIndex((event) => event.title === title);

        if (index > -1) {
          events_list.splice(index, 1);
        }
      }
    }

    return events_list;
  };

  handleSortByKeyword() {
    const events = this.state.events.events.slice();

    let sorted_events = [];
    const { include, exclude } = this.state.filters;

    const arr = sorted_events.length > 0 ? sorted_events : events;

    if (include.length > 0) {
      sorted_events = this.handleSetIncludesEvents(arr, include);
    }

    if (exclude.length > 0) {
      sorted_events = this.handleSetExcludeEvents(arr, exclude);
    }

    this.setState({ events_list: sorted_events });
  }

  togglePasswordModal = () => {
    this.setState((prevState) => ({
      ...prevState,
      show_pwd_modal: !prevState.show_pwd_modal,
    }));
  };

  handleCheckPassword = (pwd) => {
    if (this.state.events_password === pwd) {
      this.togglePasswordModal();
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.sort_types !== this.state.sort_types) {
      this.handleSortEvents();
    }

    if (prevState.events.events !== this.state.events.events) {
      this.handleSetEventKeywords();
    }

    if (prevState.events_list !== this.state.events_list) {
      this.handleSetEventKeywords();
    }

    if (prevState.filters !== this.state.filters) {
      this.handleSortByKeyword();
    }

    if (prevState.filters !== this.state.filters) {
      const { include, exclude } = this.state.filters;
      if (include.length === 0 && exclude.length === 0) {
        this.setState((prevState) => ({
          ...prevState,
          events_list: this.state.events.events,
        }));
      }
    }

    if (prevState.search_event_term !== this.state.search_event_term) {
      this.handleKeyWordSearch();
    }
  }

  componentDidMount() {
    fetch("Events.json")
      .then((response) => response.json())
      .then((data) => {
        this.setState(
          {
            events: data,
            events_list: data.events,
            headerTitle: data.header_title,
            events_password: data.password || "",
            show_pwd_modal: data.password?.length > 0 ? true : false,
            passwordModal: {},
            header_backgroundColor: data.header_backgroundColor,
            header_font_color: data.header_font_color,
          },
          () => {
            document.title = this.state.events.title;
          }
        );
      });
  }

  render() {
    const {
      events,
      events_list,
      show_pwd_modal,
      events_keywords,
      headerTitle,
      search_event_term,
      search_keyword_term,
      header_backgroundColor,
      header_font_color,
      filters,
      collapse_keywords,
    } = this.state;

    const {
      title,
      search_placeholder,
      search_keyword_placeholder,
      protected_content_text,
      password_button_text,
      ...rest
    } = events;

    return (
      <div
        style={{
          minHeight: "100vh",
          backgroundColor: events.page_background_color,
        }}
      >
        <Header
          color={header_font_color}
          backgroundColor={header_backgroundColor}
          headerTitle={headerTitle}
        />

        <Sidebar
          isOpen={this.state.is_open_sidebar}
          onClose={() => this.toggleSidebar()}
        >
          <div className="sidebar-sticky">
            <div className="sidebar-row">
              <div className="sidebar-row_title">Sort by:</div>
              <div className="sidebar-row_item">
                <div className="sidebar-row_row">
                  <SortButton
                    name="priority"
                    className={`sort-button ${
                      this.state.sort_types === "priority" ? "selected" : ""
                    }`}
                    onClick={() => this.handleSetSortType("priority")}
                  >
                    Priority
                  </SortButton>
                  <SortButton
                    name="dueDate"
                    className={`sort-button ${
                      this.state.sort_types === "dueDate" ? "selected" : ""
                    }`}
                    onClick={() => this.handleSetSortType("dueDate")}
                  >
                    Due date
                  </SortButton>
                </div>
              </div>
            </div>

            <div className="sidebar-row">
              <div
                className="sidebar-row_title"
                onClick={this.toggleKeywordsCollapse}
              >
                Key words
                <span>{collapse_keywords ? <>&#9650;</> : <>&#9660;</>}</span>
              </div>
              {collapse_keywords && (
                <div className="sidebar-row_item">
                  <input
                    type="text"
                    value={search_keyword_term}
                    onChange={(evt) =>
                      this.setState({ search_keyword_term: evt.target.value })
                    }
                    placeholder={search_keyword_placeholder}
                  />
                </div>
              )}
            </div>
          </div>

          {collapse_keywords && (
            <div className="sidebar-row">
              <div className="sidebar-row_keywords">
                {events_keywords.map((keyword, index) => (
                  <KeywordButtons
                    key={index}
                    keyword={keyword}
                    filters={filters}
                    setIncludes={this.handleSetFilterInclude}
                    setExcludes={this.handleSetFilterExclude}
                  />
                ))}
              </div>
            </div>
          )}
        </Sidebar>
        <div className="app">
          {show_pwd_modal && (
            <PasswordModal
              isShow={show_pwd_modal}
              onCheckPassword={this.handleCheckPassword}
              title={protected_content_text}
              buttonText={password_button_text}
            />
          )}

          <Toolbar
            events={events}
            value={search_event_term}
            placeholder={events.search_placeholder}
            onChange={this.handleEventSearch}
            onSidebarOpen={() => this.toggleSidebar()}
          />

          <Content>
            <Events
              eventsStyles={rest}
              events={events_list}
              modal={{
                title: protected_content_text,
                buttonText: password_button_text,
              }}
            />
          </Content>
        </div>
      </div>
    );
  }
}

export default App;
